<template>
  <div class="good">
    <div class="local">
      <span @click="showActions = true">{{ localName }}</span>
      <van-action-sheet
        class="pop-div100"
        safe-area-inset-bottom
        v-model="showActions"
        :actions="actionsLang"
        close-on-click-action
        @select="onSelectLang"
      />
    </div>
    <div class="role-action">
      <van-action-sheet
        class="pop-div100"
        safe-area-inset-bottom
        v-model="showActionsRole"
        :actions="actionsRole"
        :close-on-click-action="false"
        :close-on-click-overlay="false"
        :description="$t('selectRoleTips')"
        @select="onSelectRole"
      >
        <van-button class="confirm-btn" @click="onSelectRoleConfirm()">{{
          $t("confirm")
        }}</van-button>
      </van-action-sheet>
    </div>

    <!-- userid登录不返回角色，但是希望有个确认的过程，这里只做展示，数据不影响 -->
    <div class="role-action">
      <van-action-sheet
        class="pop-div100"
        safe-area-inset-bottom
        v-model="showActionsRoleUserId"
        :actions="actionsRoleUserId"
        :close-on-click-action="false"
        :close-on-click-overlay="false"
        :description="$t('selectRoleTips')"
      >
        <van-button
          class="confirm-btn"
          @click="showActionsRoleUserId = false"
          >{{ $t("confirm") }}</van-button
        >
      </van-action-sheet>
    </div>

    <van-swipe class="my-swipe1" :autoplay="3000" indicator-color="white">
      <van-swipe-item v-for="(item, index) in bannerList" :key="index">
        <img :src="item.img" class="scroll-img"
      /></van-swipe-item>
    </van-swipe>
    <van-tabs
      v-model="active"
      scrollspy
      sticky
      background="rgba(0, 0, 0, 0.8)"
      color="#fff"
      title-inactive-color="#fff"
      title-active-color="#fff"
      :line-width="0"
      swipe-threshold="3"
    >
      <van-tab
        v-for="(item, index) in goodList"
        :title="item.name"
        :key="index"
      >
        <van-divider class="line" content-position="left">
          {{ item.name }}
        </van-divider>
        <div
          class="good-list"
          v-for="(itemC, indexC) in item.goods"
          :title="itemC.name"
          :key="indexC"
          @click="showPopup('buy', itemC)"
        >
          <div class="img-div">
            <div
              class="img-bg"
              :style="{
                background: `url(${
                  itemC.img || defaultImg
                }) center center / contain no-repeat`,
              }"
            />
          </div>
          <div v-if="!itemC.valid" class="img-sold">{{ $t("sold") }}</div>
          <div>
            <div>
              {{ itemC.name }}
              <!-- <span class="red" v-if="itemC.tag.length > 0"
                ><van-icon name="point-gift-o" />200%</span
              > -->
            </div>
            <div class="description">{{ itemC.description }}</div>
            <div>
              <div v-if="itemC.originalPrice !== itemC.price" class="delete">
                {{ changeMoney(itemC, itemC.originalPrice) }}
              </div>
              <div class="orange" :class="!itemC.valid ? 'sold' : ''">
                {{ changeMoney(itemC, itemC.price) }}
              </div>
            </div>
          </div>
        </div>
      </van-tab>
    </van-tabs>
    <div class="to-bottom">{{ $t("noMore") }}</div>
    <van-empty v-if="goodList.length === 0" />
    <div class="bottom-btns">
      <van-button class="left-btn" type="default" @click="showPopup('faq')"
        ><van-icon class="btn-icon" name="question-o" size="16" />{{
          $t("faq")
        }}</van-button
      >
      <van-button
        class="left-btn"
        type="default"
        @click="showCommunication"
        dot
      >
        <Badge :content="messageIds.length > 0 ? messageIds.length : ''">
          <van-icon class="btn-icon" name="more-o" size="16" />{{
            $t("contact")
          }}
        </Badge>
      </van-button>
      <!-- </van-badge> -->

      <van-button
        v-if="!islogin"
        class="login-btn"
        @click="showPopup(loginType)"
        ><van-icon name="user-circle-o" size="16" />{{
          $t("login")
        }}</van-button
      >
      <div class="popover-div" v-if="islogin">
        <van-popover
          v-model="showPopover"
          placement="top"
          trigger="click"
          :actions="
            rolesMany
              ? [
                  {
                    text: this.$t('switchRol'),
                    icon: 'img/refresh.png',
                    id: 1,
                  },
                  { text: this.$t('logout'), icon: 'img/logout.png', id: 2 },
                ]
              : [{ text: this.$t('logout'), icon: 'img/logout.png', id: 2 }]
          "
          :offset="[0, 20]"
          @select="onSelect"
        >
          <template #reference>
            {{ user.user_name || user.user_id }}
          </template>
        </van-popover>
      </div>
    </div>
    <div v-if="isShowCode" class="pc-only">
      <Button
        v-if="!showCode"
        class="code-div"
        type="default"
        @mouseenter="showCode = true"
      >
        <img src="../assets/img/icon/gamepad.png" alt="" />
        <p>{{ $t("playNow") }}</p></Button
      >
      <img
        v-if="showCode"
        :src="qrcode"
        alt
        srcset
        @mouseleave="showCode = false"
      />
    </div>
    <Popup
      v-model="show"
      v-if="show"
      :closeable="showDiv !== 'check'"
      lock-scroll
      overlay
      get-container="body"
      class="pop-div"
    >
      <Login
        v-if="showDiv === 'login'"
        :radio="radioValue"
        @showPopup="showLogin"
        @showRolePopup="showActionsRole = true"
      />
      <LogUser
        v-if="showDiv === 'logUser'"
        :logIcon="logIcon"
        :fbLogType="fbLogType"
        @showPopup="showLogin"
        @showNoRolePopup="userIdFun"
      />

      <Buy
        v-if="showDiv === 'buy' && checkedDiv"
        :curDetail="checkedDiv"
        :sandbox="sandbox"
        :roleName="currentRoleName"
        :itermNorth="itermNorth"
        @showPopup="show = false"
        @showLoading="showLoading"
        @showCheck="showCheck"
      />
      <Faq v-if="showDiv === 'faq'" :list="faqContent" />
      <Result
        v-if="showDiv === 'result'"
        @showPopup="show = false"
        :gameUrl="gameUrl"
        :isShowCode="isShowCode"
      />
      <Check
        v-if="showDiv === 'check'"
        @showPopup="show = false"
        :iframeURL="iframeURL"
      />
    </Popup>

    <!-- <van-overlay :show="showCheckPop" @click="show = false">
      <div class="wrapper" @click.stop>
        <van-loading type="spinner" />
        <Check
          v-if="showCheckPop"
          @showPopup="showCheckPop = false"
          :iframeURL="iframeURL"
        />
      </div>
    </van-overlay> -->

    <!-- 客服消息即时通讯 -->
    <!-- <action-sheet
      v-model="communicationVisible"
      class="message-dialog"
      title="标题"
    > -->
    <Popup
      v-model="communicationVisible"
      closeable
      lock-scroll
      overlay
      get-container="body"
      position="bottom"
      class="pop-div100"
      :safe-area-inset-bottom="true"
    >
      <!-- <div class="content"> -->
      <Message
        :messageList="messageList"
        @getMessageList="gethestoryMessage"
      ></Message>
      <!-- </div> -->
    </Popup>
    <!-- </action-sheet> -->
    <!-- <van-overlay :show="showLoad" @click="show = false">
      <div class="wrapper" @click.stop>
        <van-loading type="spinner" />
      </div>
    </van-overlay> -->
  </div>
</template>

<script>
import {
  Popup,
  Swipe,
  SwipeItem,
  Tab,
  Tabs,
  Icon,
  Button,
  Form,
  Field,
  Checkbox,
  divider,
  Popover,
  Toast,
  loading,
  Badge,
  overlay,
  empty,
  ActionSheet,
} from "vant";
import Login from "./Login.vue";
import LogUser from "./LogUser.vue";
import Faq from "./Faq.vue";
import Result from "./Result.vue";
import Check from "./Check.vue";
import Buy from "./Goods/Buy.vue";
import { v4 as uuidv4 } from "uuid";
import { banner, goods, toContinue } from "@/api";
import payLoginClick from "@/utils/fbLogin";
import Message from "@/components/TimesMessage";
import { hestoryMessageApi, sendAlreadyReadApi } from "@/api/message";
// import {doTranslate} from "@/utils/tool"

export default {
  name: "Index",
  components: {
    Popup,
    [Swipe.name]: Swipe,
    [SwipeItem.name]: SwipeItem,
    [Tab.name]: Tab,
    [Tabs.name]: Tabs,
    [Icon.name]: Icon,
    [Button.name]: Button,
    [Form.name]: Form,
    [Field.name]: Field,
    [Checkbox.name]: Checkbox,
    [divider.name]: divider,
    [Popover.name]: Popover,
    [Toast.name]: Toast,
    [loading.name]: loading,
    [overlay.name]: overlay,
    [empty.name]: empty,
    [ActionSheet.name]: ActionSheet,
    Badge,
    Faq,
    Login,
    Buy,
    LogUser,
    Message,
    Result,
    Check,
  },
  props: {
    msg: String,
  },
  data() {
    return {
      show: false,
      resultList: new Array(),
      nodata: false,
      active: 4,
      activeTabbar: 0,
      showDiv: "",
      showCode: false,
      showPopover: false,
      islogin: localStorage.getItem("token") ? true : false, //是否登录
      bannerList: [], //banner返回值
      goodList: [], //商品列表
      checkedDiv: {}, //被选的那一个
      user: JSON.parse(localStorage.getItem("user")),
      faqContent: [], //faq内容
      qrcode: "", //pc端二维码地址
      loginType: "login", //userid登录或者用户密码登录
      communicationVisible: false,
      timer: null,
      messageList: [],
      messageIds: [], // 存储未读消息的id
      publicPath: process.env.BASE_URL,
      showLoad: false,
      gameUrl: "", //支付完成后调整的游戏地址
      iframeURL: "", //支付地址
      showCheckPop: false,
      sandbox: false,
      defaultImg: require("../assets/img/gift.jpg"),
      showActions: false,
      actionsLang: [
        { name: "English", value: "en" },
        { name: "中文", value: "zh" },
        { name: "Deutsh", value: "de" }, //德语
        { name: "Русский", value: "ru" }, //俄语
        { name: "Português", value: "pt" }, //葡萄牙语
      ],
      langArr: ["en", "zh", "de", "ru", "pt"],
      defaultName: {
        zh: "希望之人",
        en: "Person of Hope",
        de: "Hoffnungsträger",
        ru: "Воплощение Надежды",
        pt: "Pessoa de Esperança",
      },
      localName: "English",
      currency: {
        USD: "$",
        CNY: "￥",
        EUR: "€",
        RUB: "₽",
      },
      showActionsRole: false,
      showActionsRoleUserId: false, // 针对userid登录做展示用
      actionsRole: [],
      actions: [
        { text: this.$t("switchRol"), icon: "img/logout.png", id: 1 },
        { text: this.$t("logout"), icon: "img/logout.png", id: 2 },
      ],
      currentSite: "",
      rolesMany: false,
      currentRoleName: "",
      radioValue: null,
      isShowCode: true,
      projectSetting: null, //当前项目配置信息
      logIcon: "", //在登录页面显示icon Url 有值就显示
      fbLogType: false, //是否要显示Facebook登录
      actionsRoleUserId: [], // 存储无用户返回的信息
      itermNorth:"",// 针对north tower项目自定义item内容
    };
  },
  watch: {
    islogin: function () {
      this.getGoods();
      this.getMessageList();
    },
    communicationVisible: {
      handler(newvalue) {
        if (newvalue) {
          this.$nextTick(() => {
            var container = document.getElementById("container");
            container && container.scrollIntoView();
          });
        }
      },
    },
    messageList: {
      handler(newvalue) {
        if (newvalue.length > 0) {
          this.$nextTick(() => {
            var container = document.getElementById("container");
            container && container.scrollIntoView();
          });
        }
      },
      deep: true,
      immediate: true,
    },
    messageIds: {
      handler(newvalue) {
        if (newvalue.length > 0) {
          this.$nextTick(() => {
            var container = document.getElementById("container");
            container && container.scrollIntoView();
          });
        }
      },
      deep: true,
      immediate: true,
    },
    showPopover: function (val) {
      if (val) {
        let tempTime = setTimeout(() => {
          this.showPopover = false;
          clearInterval(tempTime);
        }, 5000);
      }
    },
    showActions: function (val) {
      if (val) {
        this.showPopover = false;
        return;
      }
    },
    showDiv: function (val) {
      console.log(val, val === "login", "showDiv");
    },
  },
  mounted() {
    // doTranslate() // 翻译文件转成相应的JSON数据
    // this.getOsInfo(); // 不需要本地获取平台相关属性
    this.languageAndResult(); // 获取返回结果处理和语言处理
    // 监听 resize 方法
    window.addEventListener("resize", this.renderResize, false);
    process.env.HTTPS = true;
    if (localStorage.getItem("token")) {
      this.changeToken().then(() => {
        this.fetchRequests();
      });
      //判断是否有 token
      sessionStorage.removeItem("uuid"); //如果有，清除 sessionStorage 中的 uuid
    } else {
      //未登录状态生成 uuid
      let uuid = sessionStorage.getItem("uuid");
      if (!uuid) {
        sessionStorage.setItem("uuid", uuidv4());
      }
      this.fetchRequests();
    }
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.renderResize, false);
  },
  methods: {
    languageAndResult() {
      let temp = location.href.split("/")[2];
      temp =
        process.env.NODE_ENV == "development"
          ? process.env.VUE_APP_BASE_URL.split("/")[2]
          : temp; //本地启动从配置环境里面取值
      // console.log(temp, process.env);

      if (temp == "mall.northtowergame.com") {
        this.actionsLang = [
          { name: "English", value: "en" },
          { name: "中文", value: "zh" },
          { name: "Deutsh", value: "de" }, //德语
          { name: "Русский", value: "ru" }, //俄语
          { name: "Português", value: "pt" }, //葡萄牙语
          { name: "Español", value: "es" }, //西班牙语
          { name: "Français", value: "fr" }, //法语
          { name: "Indonesia", value: "id" }, //印尼语
          { name: "Italiano", value: "it" }, //意大利语
          { name: "日本語", value: "ja" }, //日语
          { name: "한국인", value: "ko" }, //韩语
          { name: "Melayu", value: "ms" }, //马来语
          { name: "ภาษาไทย", value: "th" }, //泰语
          { name: "Tiếng Việt", value: "vi" }, //越南语
        ];
        this.langArr = [
          "en",
          "zh",
          "de",
          "ru",
          "pt",
          "es",
          "fr",
          "id",
          "it",
          "ja",
          "ko",
          "ms",
          "th",
          "vi",
        ];
      }
      let language = ''
      if (window.location.search.substring(1)) {
        //user_id=mergerpg%3A123&status=done&invoice_id=822154087
        let res = this.getQueryString("status");
        if (res === "done") {
          this.showPopup("result"); //预留支付成功处理
        }
        language = localStorage.getItem("language"); // 获取本地的语言设置
        this.getQueryString("language") &&
          (language = this.getQueryString("language")); // 兼容query参数的语言设置

        // if (this.langArr.includes(language)) {
        //   this.$i18n.locale = language; // 支持接收语言参数
        //   this.localName = this.actionsLang.filter(
        //     (o) => o.value == this.$i18n.locale
        //   )[0].name;
        // }
      }
      language = language || localStorage.getItem("language") || 'en'
      if (this.langArr.includes(language)) {
          this.$i18n.locale = language; // 支持接收语言参数
          this.localName = this.actionsLang.filter(
            (o) => o.value == this.$i18n.locale
          )[0].name;
        }
    },
    userIdFun(val) {
      this.actionsRoleUserId = [val];
      this.showActionsRoleUserId = true;
    },
    getOsInfo() {
      var userAgent = navigator.userAgent.toLowerCase();
      if (userAgent.indexOf("iphone") > -1) {
        return localStorage.setItem("platform", 2);
      } else if (userAgent.indexOf("android") > -1) {
        return localStorage.setItem("platform", 1);
      }
      return localStorage.setItem("platform", null);
    },
    onSelectRole(item) {
      this.actionsRole.forEach((i) => {
        // 选完之后可以回显颜色，反应慢，不建议用
        i.color = "#323233";
        if (i.id == item.id) {
          i.color = "#ee5b24";
        }
      });
      // console.log("选择", item);
      this.currentRoleName = item.name;
      localStorage.setItem("currentRoleLogin", JSON.stringify(item));
      // this.showActionsRole = true;
    },
    onSelectRoleConfirm() {
      //选择用户ID做确认操作
      if (!localStorage.getItem("currentRoleLogin")) {
        return Toast(this.$t("confirmTips"));
      }
      this.showActionsRole = false;
    },
    onSelectLang(item) {
      // 默认情况下点击选项时不会自动收起
      // 可以通过 close-on-click-action 属性开启自动收起
      this.show = false;
      this.localName = item.name;
      localStorage.setItem("language", item.value);
      this.changeLang(item.value);
    },
    renderResize() {
      this.readJson();
    },
    fetchRequests() {
      this.getBanner();
      this.getGoods();
      this.readJson();
      this.getMessageList();
    },
    getQueryString(name) {
      let query = window.location.search.substring(1); //?user_id=123&status=done&invoice_id=811031479
      let vars = query.split("&");
      for (let i = 0; i < vars.length; i++) {
        let pair = vars[i].split("=");
        if (pair[0] == name) {
          return pair[1];
        }
      }
      return false;
    },
    aa() {
      //fblogin
      payLoginClick();
    },

    async changeToken() {
      let res = await toContinue();
      // console.log(res.data.token, "tokenm");
      this.$store.commit("setToken", res.data.token);
    },
    changeMoney(item, val) {
      return this.currency[item.currency] + val;
    },
    changeRoleData() {
      let current = localStorage.getItem("currentRoleLogin")
        ? JSON.parse(localStorage.getItem("currentRoleLogin"))
        : null;
      current && (this.currentRoleName = current.name);
      let tempRole = localStorage.getItem("roles")
        ? JSON.parse(localStorage.getItem("roles"))
        : null; //单一角色直接转换
      this.actionsRole = [];
      let temp = this.$i18n.locale === "ru" ? "C" : "S";
      // console.log(current, "current");

      if (tempRole) {
        //val === "mall.age-of-guardians.com"  去掉了这个条件不知道当时为什么这么加
        // console.log(this.actionsRole, 1);
        tempRole.map((item) => {
          if (!item.name) {
            item.name =
              temp +
              item.rid +
              " / " +
              this.defaultName[this.$i18n.locale] +
              item.id;
          } else {
            item.name = temp + item.rid + " / " + item.name;
          }
          item.color = "#323233";
          this.actionsRole.push(item);
          this.actionsRole.length > 1 && (this.rolesMany = true); // 多角色可以换角色
        });
      }
      // if (this.actionsRole.length === 1) {
      //   // console.log(this.actionsRole, 2);

      //   this.currentRoleName = this.actionsRole[0].name;
      //   localStorage.setItem(
      //     "currentRole",
      //     JSON.stringify(this.actionsRole[0])
      //   );
      //   this.rolesMany = false;
      // } else if (this.actionsRole.length > 1) {
      // && current 去掉这个条件不知道当时为什么加
      //改变语言的同时要手动改变存下的当前选择的角色
      // console.log(this.actionsRole, 3);

      // let tempRole = this.actionsRole.filter(
      //   (item) => item.id == JSON.parse(current).id
      // )[0];
      // this.currentRoleName = tempRole.name;
      // localStorage.setItem("currentRole", JSON.stringify(tempRole));
      // this.rolesMany = true;
      // }

      // console.log(this.currentRoleName, "currentRole");
    },
    readJson() {
      let staticResources = this.$t("setting");
      let temp = location.href.split("/")[2];
      temp = !staticResources[temp]
        ? process.env.VUE_APP_BASE_URL.split("/")[2]
        : temp; //本地启动从配置环境里面取值
      this.isShowCode = !staticResources[temp]["notShowCode"]; // 针对越南地区， pc 端不显示二维码
      this.changeRoleData();
      this.projectSetting = staticResources[temp]; //根据项目获取当前配置信息
      this.faqContent = staticResources[temp] && staticResources[temp].faq;
      this.qrcode = staticResources[temp]["qrcode"];
      // 配置标题
      document.title = staticResources[temp].documentTitle;
      let email = staticResources[temp].email;
      localStorage.setItem("documentTitle", document.title);
      localStorage.setItem("email", email);

      let width =
        document.documentElement.clientWidth || document.body.clientWidth;
      // console.log(width, "pcUrl", staticResources[temp]["pcBackground"]);
      if (width > 1125 && staticResources[temp]["pcBackground"]) {
        // console.log("url", this.publicPath);
        document.body.style.backgroundImage =
          "url('" +
          this.publicPath +
          staticResources[temp]["pcBackground"] +
          "')";

        let tempStyle = `
            body::before{background-image: url(${
              this.publicPath + staticResources[temp]["pcBackground"]
            });
            } 
            `;
        let style = document.createElement("style");
        style.innerHTML = tempStyle;
        document.getElementsByTagName("head").item(0).appendChild(style);
      } else {
        document.body.style.backgroundImage = "none";
      }
      this.loginType = staticResources[temp]["loginType"] || "logUser"; //登录类型，login是用户名和密码登录
      this.logIcon = staticResources[temp]["iconUrl"];
      // console.log(staticResources[temp]);
      this.fbLogType = staticResources[temp]["fbLogType"];
      if (/(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent)) {
        this.gameUrl =
          staticResources[temp]["iosUrl"] || staticResources[temp]["gameUrl"];
      } else {
        this.gameUrl =
          staticResources[temp]["androidUrl"] ||
          staticResources[temp]["gameUrl"];
      }
      // this.sandbox = staticResources[temp]["sandbox"];从后端返回不走配置文件了
      this.itermNorth = staticResources[temp]["itermNorth"];
      let projectInfo = JSON.parse(localStorage.getItem("projectInfo"));
      this.sandbox = projectInfo.sandbox;

    },
    async getBanner() {
      let res = await banner({ language: this.$i18n.locale });
      this.bannerList = res.data.list;
    },
    async getGoods() {
      let params = {};
      // console.log(localStorage.getItem("user"), 'localStorage.getItem("user")')
      if (localStorage.getItem("user")) {
        let user = JSON.parse(localStorage.getItem("user"));
        if (user.roles && user.roles.length > 0) {
          let { platform, pkg, id } = user.roles[0];
          params = { platform, pkg, role_id: id };
        } else {
          let { platform, pkg, id } = user;
          params = { platform, pkg, role_id: id };
        }
      }
      
      params.language = this.$i18n.locale;
      // console.log(params, 'params')
      let res = await goods(params);
      this.goodList = res.data.list;
    },

    onSelect(action) {
      //退出登录在这里调用接口
      if (action.id === 1) {
        this.showActionsRole = true;
      } else {
        this.islogin = false;
        localStorage.removeItem("token");
        localStorage.removeItem("user");
        localStorage.removeItem("roles");
        localStorage.removeItem("currentRole");
        localStorage.removeItem("currentRoleLogin");
        clearInterval(this.timer);
        Toast(this.$t("logoutText"));
        this.getGoods();
      }
    },
    changeLang(val) {
      this.local = val;
      this.$i18n.locale = val;
      this.$store.commit("setLang", val);
      this.readJson();
      this.getBanner();
      this.getGoods();
    },
    showLoading(val) {
      this.showLoad = val;
    },
    //val:具体组件名字， item。打开组件带的参数
    showPopup(val, item) {
      // console.log(val, 'val----item', item)
      let data = null;
      if (val == "buy") {
        data = { goodsId: item.id };
        DotLog.clickLogs("goods", data); //点击按钮打点传参
        this.checklogin();
        if (!this.islogin) {
          return Toast(this.$t("loginFirst"));
        }
        if (!item.valid) {
          return Toast(this.$t("alreadyPurchased"));
        }
        // console.log(this.projectSetting,"有没有角色")
        if (!this.currentRoleName && this.projectSetting.needRole) {
          return (this.showActionsRole = true);
        }
      }
      if (val == "faq") {
        DotLog.clickLog("faq-click"); //点击按钮打点传参
      }
      data ? DotLog.viewLog(val, data) : DotLog.pageReady(val); //页面进入及传参
      this.show = true;
      this.showDiv = val;
      // this.checkedDiv = null
      // console.log(item, 'itewm')
      this.checkedDiv = item;
      // console.log(this.checkedDiv, 'checkedDiv===')
    },
    checklogin() {
      if (!localStorage.getItem("user")) {
        this.islogin = false;
        return Toast(this.$t("loginFirst"));
      } else {
        this.islogin = true;
      }
    },
    // 打开客服聊天面板
    showCommunication() {
      // if (!this.islogin) {
      //   DotLog.clickLog("contact-click");
      //   return Toast(this.$t("loginFirst"));
      // }
      // 暂时不需要打开聊天面板，改为用邮箱联系的方式
      
      const emailLink = document.createElement('a');
      emailLink.href = 'mailto:support@northtowergame.com';
      emailLink.target = '_blank';
      emailLink.click();
      DotLog.clickLog("contact-click");

      // DotLog.pageReady("contact");
      // this.communicationVisible = true;
      // this.gethestoryMessage();
    },
    //登录窗口交互
    showLogin(val = false, islogin) {
      // console.log(val, islogin, "showlogin");
      this.show = val;
      this.islogin = islogin;
      this.getGoods();
      this.user = JSON.parse(localStorage.getItem("user"));

      this.actionsRole = localStorage.getItem("roles")
        ? JSON.parse(localStorage.getItem("roles"))
        : [];
      if (this.actionsRole.length > 0) {
        this.rolesMany = true;
        localStorage.removeItem("currentRole");
      }
      this.readJson();
    },
    showCheck(val, item) {
      // console.log(val, item, "check");
      this.iframeURL = item;
      this.show = true;
      this.showDiv = val;
    },
    // 轮询客服聊天面板
    getMessageList() {
      this.gethestoryMessage();
      clearInterval(this.timer);
      this.timer = null;
      this.timer = setInterval(() => {
        this.gethestoryMessage();
      }, 15000);
    },
    /**
     * 获取到最近用户交流记录
     */
    async gethestoryMessage() {
      let { userId, pscode } = this.mergeParams();
      if (!userId || !pscode) {
        clearInterval(this.timer);
        this.timer = null;
        return;
      }
      let parmas = {
        userId,
        pscode,
      };
      let res = await hestoryMessageApi(parmas);
      this.showDot = 0;
      this.messageIds = [];
      this.messageList.forEach((item) => {
        if (!item.playerRead) {
          // this.showDot += 1
          this.messageIds.push(item.id);
        }
      });
      // 通知后端的当前消息已读 如果未读消息的数组大于0 并且当前对话框属于打开的情况，则通知后端。已读
      if (this.messageIds.length > 0 && this.communicationVisible) {
        let { userId, pscode } = this.mergeParams();
        // userId = '77970719253725646',
        let parmas = {
          userId,
          pscode,
          messageIds: this.messageIds.join(","),
        };
        let res = sendAlreadyReadApi(parmas);
        if (res.code === 200) {
          this.messageIds = [];
        }
      }
      this.messageList = res.data.messages;
    },
    /**
     * 组合参数信息
     */
    mergeParams() {
      // console.log(JSON.parse(localStorage.getItem("user")))
      if (!this.islogin) {
        return {};
      }
      let { user_id } = JSON.parse(localStorage.getItem("user"));
      let { id } = localStorage.getItem("currentRole")
        ? JSON.parse(localStorage.getItem("currentRole"))
        : "";
      let projectInfo = JSON.parse(localStorage.getItem("projectInfo"));
      const params = {
        roleId: id || user_id,
        userId: user_id,
        pscode: projectInfo.project_code,
        text: this.userword,
      };
      return params;
    },
  },
};
</script>

<style  lang="scss" scoped>
.good {
  margin-bottom: 150px;

  .my-swipe {
    .van-swipe-item {
      color: #fff;
      font-size: 20px;
      text-align: center;
      height: 56.25vmin;
    }
  }

  .to-bottom {
    color: #c5c5c5;
    text-align: center;
    font-size: 12px;
    height: 100px;
    line-height: 50px;
  }
  .line {
    color: #fff;
    border-color: #fff;
    font-size: 18px;
    margin: 30px 0;
  }
  .bottom-btns {
    position: fixed;
    bottom: 0;
    width: 100%;
    font-size: 12px;
    button {
      width: 50%;
      height: 50px;
    }
    .left-btn {
      width: 25%;
      color: #ee5b24;
    }
    .btn-icon {
      display: block;
    }
    background-color: #ee5b24;
    border: none;
    /deep/.van-button--normal {
      padding: 0 !important;
      line-height: 1 !important;
    }
  }
}
.login-btn {
  background-color: #ee5b24;
  color: #fff;
  border: 1px solid #ee5b24;
  display: inline-block;
  width: 50%;
}
.confirm-btn {
  background-color: #ee5b24;
  color: #fff;
  border: 1px solid #ee5b24;
  display: inline-block;
  font-weight: bold;
  width: 100%;
}
.pc-only {
  display: none;
  position: fixed;
  right: 100px;
  bottom: 100px;
  & > button {
    width: 70px;
    height: 70px;
    font-size: 12px;
    font-weight: bold;
    line-height: 1.5;
    color: #fff;
    background-color: #101010;
    border: none;
    margin: 20px;
    & .van-icon {
      font-size: 25px !important;
      display: block;
    }
    & > p {
      line-height: 1;
    }
  }

  & > img {
    width: 200px;
    height: 200px;
  }
}
.pop-div {
  width: calc(100% - 30px);
  // height: 500px;
  // z-index: 2002;
  // top: 100px;
  // background-color: transparent;
  // left: -1.77rem;
  // margin-left: 50%;
  // box-sizing: border-box;
  // overflow-x: hidden;
}
.pop-div100 {
  width: 100%;
  overflow: none;
}
.message-dialog {
  width: 100%;
  margin: 0 auto;
  text-align: center;
  left: -1.845rem;
  margin-left: 50%;
  border: 5px solid rgb(64, 0, 255);
  overflow-y: hidden;
}
.local {
  position: absolute;
  right: 10px;
  top: 5px;
  z-index: 1;
  font-size: 16px;
  text-align: right;
  color: #fff;
  font-weight: bold;
  cursor: pointer;
  background: rgba(0, 0, 0, 0.6);
  padding: 5px;
  .active {
    color: #ee5b24;
  }
}
.popover-div {
  width: 50%;
  display: inline-block;
  background: #ee5b24;

  .van-popover__wrapper {
    line-height: 35px;
    position: relative;
    top: 5px;
    width: 100%;
    color: #fff;
    font-size: 16px;
  }
}
.van-action-sheet__header {
  background-color: #ee5b24;
  color: #ee5b24;
}
::v-deep .van-action-sheet__header {
  border-radius: 0px;
}
.van-popup--bottom.van-popup--round {
  border-radius: 0;
}
</style>
