<template>
  <div class="wrap">
    <p>{{$t("faq")}}</p>
    <van-collapse v-model="activeNames" class="faq-content">
      <van-collapse-item
        v-for="(item, index) in list"
        :title="item.name"
        :name="index"
        :key="index"
        >{{ item.content }}</van-collapse-item
      >
    </van-collapse>
  </div>
</template>
<script>
import { collapse, CollapseItem } from "vant";
export default {
  name: "FAQ",
  props: {
    list: {
      type: Array,
      default: () => [],
    },
  },
  components: {
    [collapse.name]: collapse,
    [CollapseItem.name]: CollapseItem,
  },
  data() {
    return {
      activeNames: ["1"],
    };
  },
};
</script>
<style lang="scss" scoped>
.wrap {
  color: #fff;
  width: 100%;
  margin: 0 auto;
  margin-bottom: 20px;
  background-color: rgba(0, 0, 0, 0.8);

  p {
    text-align: left;
    background: #ee5b24;
    color: #fff;
    font-size: 26px;
    height: 50px;
    line-height: 50px;
    padding-left: 20px;
  }
  .faq-content {
    max-height: 400px;
    overflow-y: scroll;
  }
}
</style>