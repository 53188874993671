<template>
  <div class="wrap">
    <div class="head" @click="$emit('showPopup')">
      <van-icon name="cross" />
    </div>
    <van-loading v-if="loading" type="spinner" />

    <iframe
      id="iframeContain"
      name="iframeContain"
      seamless
      scrolling="yes"
      :src="iframeURL"
    >
      您当前的浏览器不支持页面上的功能，请升级您当前的浏览器版本或使用谷歌浏览器访问当前页面
    </iframe>
  </div>
</template>
<script>
import { Icon, loading } from "vant";

export default {
  name: "Check",
  props: {
    iframeURL: {
      type: String,
      default: "",
    },
  },
  components: {
    [Icon.name]: Icon,
    [loading.name]: loading,
  },
  data() {
    return { loading: true };
  },
  mounted() {
    setTimeout(() => {
      this.loading = false;
    }, 3000);
  },
  methods: {
    openGame() {
      if (this.gameUrl) {
        window.location.href = this.gameUrl;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
/deep/ .van-popup__close-icon {
  color: #000 !important;
}
.wrap {
  background-color: rgba(0, 0, 0, 0.7);
  text-align: center;
  width: 100%;
  height: 100%;
  min-height: 500px;
  .head {
    position: absolute;
    right: 20px;
    top: 5px;
    font-size: 20px;
    color: #000;
  }
  #iframeContain {
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    overflow-y: scroll;
    background-color: rgba(0, 0, 0, 0.7);
    min-height: 500px;
    body {
      background-color: #fff;
    }
  }
}
</style>