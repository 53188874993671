<template>
  <div class="login-wrap">
    <p>Gamehaus</p>
    <van-form @submit="onSubmit">
      <van-field
        v-model="account"
        name="account"
        class="input-border"
        :placeholder="$t('needAccount')"
        maxlength="50"
        :rules="[{ required: true }]"
      />
      <van-field
        v-model="password"
        type="password"
        class="input-border"
        name="password"
        :placeholder="$t('needPassword')"
        :rules="[{ required: true }]"
      />

      <van-radio-group
        v-model="radio"
        class="radio-div"
        direction="horizontal"
        @change="changeRadio"
      >
        <van-radio :name="1" checked-color="#ee5b24">
          <img src="../assets/img/icon/android.png" class="img-25w" alt="" />
        </van-radio>
        <van-radio :name="2" checked-color="#ee5b24">
          <img src="../assets/img/icon/ios.png" class="img-w" alt="" />
        </van-radio>
      </van-radio-group>

      <van-checkbox
        v-model="checked"
        shape="square"
        checked-color="#EE5B24"
        class="remember"
        @change="change"
        ><span class="color-orange">{{ $t("remeber") }}</span></van-checkbox
      >

      <div>
        <van-button block class="login-btn" type="info" native-type="submit">{{
          $t("login")
        }} </van-button>
        <!-- <p class="splict">{{ $t("or") }}</p> -->
      </div>
    </van-form>
    <!-- <van-button
      block
      type="info"
      scope="public_profile,email"
      @click="fbLogin()"
      >{{ $t("fbLog") }}</van-button
    > -->
  </div>
</template>
<script>
import {
  Icon,
  Button,
  Form,
  Field,
  Checkbox,
  RadioGroup,
  Radio,
  Toast,
} from "vant";
// import Facebook from "./Facebook.vue";
import { login } from "@/api";
import payLoginClick from "@/utils/fbLogin";
import { dotInit } from "@/utils";

export default {
  name: "Login",
  components: {
    [Icon.name]: Icon,
    [Button.name]: Button,
    [Form.name]: Form,
    [Field.name]: Field,
    [Checkbox.name]: Checkbox,
    [RadioGroup.name]: RadioGroup,
    [Radio.name]: Radio,
    [Toast.name]: Toast,
  },
  data() {
    return {
      account:
        localStorage.getItem("UserLogin") &&
        JSON.parse(localStorage.getItem("UserLogin")).account,
      password:
        localStorage.getItem("UserLogin") &&
        JSON.parse(localStorage.getItem("UserLogin")).password,
      checked: true,
      radio: null,
    };
  },
  created() {
    if (localStorage.getItem("platform")) {
      this.radio = Number(localStorage.getItem("platform"));
    }

    FB.init({
      appId: JSON.parse(localStorage.getItem("projectInfo")).app_id,
      cookie: true,
      xfbml: true,
      version: "v2.9",
    });
    // FB.AppEvents.logPageView();
    console.log("app facebook init");
    // FB.getLoginStatus((response) => {
    //   console.log("res from app", response); // 這裡可以得到 fb 回傳的結果
    // });
  },
  methods: {
    changeRadio(val) {
      localStorage.setItem("platform", val);
    },
    change() {
      if (this.checked) {
        localStorage.setItem(
          "UserLogin",
          JSON.stringify({ account: this.account, password: this.password })
        );
      } else {
        localStorage.removeItem("UserLogin");
      }
    },
    fbLogin() {
      payLoginClick();
      this.$emit("showPopup");
    },
    async onSubmit() {
      if (!this.radio) {
        return Toast(this.$t("choosePlatform"));
      }
      this.change();
      let res = await login({ account: this.account, password: this.password });
      // let temp =
      //   '{"code":200,"data":{"token":"eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJpYXQiOjE2MzE2ODgzMTIsImV4cCI6MTYzMTY5MTkxMiwidXNlcl9pZCI6IjEyMyIsInByb2plY3RfaWQiOiJtZXJnZXJwZyJ9.n02hzUseERMP4rqqPc16DkhliuyjqqJzU4D8nGkcVbpnN2fqbpKdWGhj3V-efyo1ISXqSqNCmFIsdkMKhDj5PSE0Rz-9nfSJnciWR6Q44rYspn2YF3EtaarwIRFsLpbbakVvyPdy6i5Dh3nqyiLCVdgmUmViYfncm9q6Y0PGxn0wJkvmqmC85ePpBqq7oQcNCwBQ2OFzMmZ2qY3rqVZzfDoxnxzjBU6uRBxDVTxgJVFDt9f75Ed9fgo_dnrvKutU-j6T7wlMvuooodBsmZTnFdguEu7N3B0QwS136rNtg4h0EhvLC6ExqFGu-BKspK1EBlbM8nAUKZYa4qrsF19thg","user_id":"123","user_name":"test123","roles":[{"id":"321","name":"\u51b2\u699c\u5927\u4f6c\uff08320\u533a-\u9b54\u754c\u4f20\u5947\uff09","pkg":"com.test","platform":"IOS"}]}}';
      // let res = JSON.parse(temp);
      if (res) {
        dotInit();
        this.$store.commit("setToken", res.data.token);
        this.$store.commit("setUser", res.data);
        if (res.data.roles && res.data.roles.length > 0) {
          localStorage.setItem("roles", JSON.stringify(res.data.roles));
          this.$emit("showRolePopup");
        } else {
          //没有角色把登录参数存起来
          localStorage.setItem("currentNoRoleLogin", JSON.stringify(res.data));
        }
        this.$emit("showPopup", false, true);
      }
    },
    login() {
      FB.login(
        function (response) {
          console.log("res", response);
        },
        {
          scope: "email, public_profile",
          return_scopes: true,
        }
      );
    },
    statusChangeCallback(response) {
      // Called with the results from FB.getLoginStatus().
      // console.log("statusChangeCallback");
      // console.log(response); // The current login status of the person.
      if (response.status === "connected") {
        // Logged into your webpage and Facebook.
        this.testAPI();
      } else {
        // Not logged into your webpage or we are unable to tell.
        document.getElementById("status").innerHTML =
          "Please log " + "into this webpage.";
      }
    },

    checkLoginState() {
      // Called when a person is finished with the Login Button.
      window.FB.getLoginStatus(function (response) {
        console.log(response, "返回的狀態");
        // See the onlogin handler
        this.statusChangeCallback(response);
      });
    },

    fbAsyncInit() {
      window.FB.init({
        appId: "636588860491257",
        cookie: true, // Enable cookies to allow the server to access the session.
        xfbml: true, // Parse social plugins on this webpage.
        version: "v2.9", // Use this Graph API version for this call.
      });

      window.FB.getLoginStatus(function (response) {
        // Called after the JS SDK has been initialized.
        this.statusChangeCallback(response); // Returns the login status.
      });
    },

    testAPI() {
      // Testing Graph API after login.  See statusChangeCallback() for when this call is made.
      console.log("Welcome!  Fetching your information.... ");
      window.FB.api("/me", function (response) {
        console.log("Successful login for: " + response.name);
        document.getElementById("status").innerHTML =
          "Thanks for logging in, " + response.name + "!";
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.login-wrap {
  color: #fff;
  text-align: center;
  width: 100%;
  margin: 0 auto;
  padding: 20px;
  border: 1px solid #ee5b24;
  height: 420px;
  background-color: rgba(0, 0, 0, 0.8);

  p {
    text-align: left;
    color: #ee5b24;
    font-size: 36px;
    font-weight: bold;
    line-height: 60px;
  }
  .splict {
    color: #fff;
    height: 0.6rem;
    font-size: 0.14rem;
    text-align: center;
    font-weight: normal;
  }
  .remember {
    margin: 10px 0;
  }
  .input-border {
    margin-bottom: 20px;
    height: 50px;
    line-height: 1;
  }
}
.color-orange {
  color: #ee5b24;
}
.login-btn {
  background-color: #ee5b24;
  color: #fff;
  border: 1px solid #ee5b24;
}
.img-w {
  width: 20px;
}
.img-25w {
  width: 25px;
}
.radio-div {
  margin: 25px 0;
  & > div {
    margin-right: 50px;
  }
}
</style>