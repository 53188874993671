import api from './https'
// 查询用户交流记录
export const hestoryMessageApi = (data) => api.post(`/api/web/message/list`, {
  ...data
})
// 发送文字消息
export const sendTextApi = (data) => api.post('/api/web/message/text/new', {
  ...data
})
// 发送图片消息
export const sendPicApi = data => api.post('/api/web/message/pic/new', data, {
  jsonTransfor: 'multipart/form-data'
})
// 通知服务端所有消息目前的客服记录已读
export const sendAlreadyReadApi = data => api.post('/api/web/message/read', data)