<template>
  <div class="wrap">
    <div class="buySuccess">
      <van-icon name="passed" /> {{ $t("result.buySuccess") }}
    </div>
    <div class="buySuccessText">
      {{ $t("result.buySuccessText") }}
    </div>
    <div class="bottom-btns-result">
      <van-button
        v-if="isNotPc||isShowCode"
        class="left-btn"
        @click="
          $emit('showPopup');
          $router.push('index');
        "
        >{{ $t("result.here") }}</van-button
      >
      <van-button
        v-if="isNotPc||isShowCode"
        class="right-btn"
        type="default"
        @click="openGame"
        >{{ $t("result.open") }}</van-button
      >
    </div>
  </div>
</template>
<script>
import { Icon, Button } from "vant";

export default {
  name: "Result",
  props: {
    gameUrl: {
      type: String,
      default: "",
    },
    isShowCode:{
      style:Boolean,
      default: true
    }
  },
  components: {
    [Icon.name]: Icon,
    [Button.name]: Button,
  },
  data() {
    return {
      isNotPc: /(iPhone|iPad|iPod|iOS|Android)/i.test(navigator.userAgent),
    };
  },
  created() {},
  methods: {
    openGame() {
      if (this.gameUrl) {
        window.location.href = this.gameUrl;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.wrap {
  background-color: rgba(0, 0, 0, 0.8);
  text-align: center;
  border: 1px solid #ee5b24;
  width: 100%;
  .buySuccess {
    height: 36px;
    color: rgba(39, 177, 72, 100);
    font-size: 28px;
    margin-top: 100px;
  }
  .buySuccessText {
    height: 36px;
    color: #fff;
    margin-top: 10px;
    font-size: 14px;
  }
  .bottom-btns-result {
    margin-top: 100px;
    width: 100%;
    font-size: 12px;
    button {
      width: 50%;
      height: 50px;
      border: none;
    }
    .left-btn {
      color: #ee5b24;

      background: transparent;
    }
    .right-btn {
      background-color: #ee5b24;
      color: #fff;
    }
    border: none;
  }
}
</style>