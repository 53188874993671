<template>
  <div>
    <div class="wrap" v-if="detail && JSON.stringify(detail) !== '{}'">
      <div class="good-about">
        <div class="img-div">
          <div
            class="img-bg"
            :style="{
              background: `url(${
                detail.img || defaultImg
              }) center center / contain no-repeat`,
            }"
          />
        </div>
        <div>
          <div class="title">{{ detail.name }}</div>
          <div>
            <span v-if="detail.originalPrice !== detail.price" class="delete">{{
              changeMoney(detail, detail.originalPrice)
            }}</span>
            <span class="normal">{{ changeMoney(detail, detail.price) }}</span>
          </div>
        </div>
      </div>
      <div class="triangle"></div>
      <div class="detail">
        <div>{{ detail.detail }}</div>
      </div>
      <van-form class="form-div">
        <!-- <van-field
          readonly
          clickable
          label="选择器"
          :value="value"
          placeholder="点击选择游戏"
          class="select-div"
          @click="showPicker = true"
        />
        <van-popup v-model="showPicker" position="bottom">
          <van-picker
            show-toolbar
            value-key="text"
            class="select"
            :columns="columns"
            @confirm="onConfirm"
            @cancel="showPicker = false"
          />
        </van-popup> -->
        <div v-if="roleName" class="self-cell">
          <div>{{ $t("buyRole") }}</div>
          <div>{{ roleName }}</div>
        </div>
        <!-- <div class="shoprightbot">
          <span class="van-field__label">数量</span>
          <div class="shopradd">
            <button @click.stop="add">-</button>
            <van-field
              style="width: 40px; text-align: center"
              readonly
              v-model="params.buy_number"
              type="number"
            />
            <button id="button" @click.stop="subtract">+</button>
          </div>
        </div> -->
        <div class="total-num">
          {{ $t("total") }}：<span>{{ changeMoney(detail, detail.price) }}</span>
        </div>
        <van-checkbox
          v-model="checked"
          shape="square"
          checked-color="#EE5B24"
          class="remember"
          icon-size="14px"
          ><span class="gray">{{ $t("agree") }}</span>
          <!-- <span class="line" @click.stop="openUrl">{{ $t("iterm") }}</span> -->
          <router-link class="line" target="_blank" :to="{ path: '/iterm' }">{{
            itermNorth?itermNorth:$t("iterm")
          }}</router-link>
        </van-checkbox>
        <div class="to-pay">
          <van-button
            class="pay-btn"
            :disabled="loading"
            type="primary"
            @click="onSubmit"
            >{{ $t("goBuy") }}</van-button
          >
          <van-button v-show="false" id="payBtn" data-xpaystation-widget-open>{{
            $t("goBuy")
          }}</van-button>
        </div>
      </van-form>
    </div>
    <div v-else>
      loading....
    </div>
  </div>
  
</template>
<script>
import {
  Icon,
  Button,
  Form,
  Field,
  Checkbox,
  popup,
  picker,
  cell,
  Toast,
} from "vant";
import { createOrder } from "@/api";
export default {
  name: "Login",
  props: {
    curDetail: {
      type: Object,
      default: () => {},
    },
    sandbox: {
      type: Boolean,
      default: false,
    },
    roleName: {
      type: String,
      default: "",
    },
    itermNorth: {
      type: String,
      default: "",
    },
  },
  components: {
    [Icon.name]: Icon,
    [Button.name]: Button,
    [Form.name]: Form,
    [Field.name]: Field,
    [Checkbox.name]: Checkbox,
    [picker.name]: picker,
    [popup.name]: popup,
    [cell.name]: cell,
    [Toast.name]: Toast,
  },
  data() {
    return {
      checked: true,
      index: 1,
      params: {
        price: 0,
        buy_number: 1,
      },
      value: "",
      columns: [],
      showPicker: false,
      iframeURL: "",
      loading: false,
      defaultImg: require("../../assets/img/gift.jpg"),
      currency: {
        USD: "$",
        CNY: "￥",
        EUR: "€",
        RUB: "₽",
      },
      detail: {}
    };
  },
  watch: {
    curDetail: {
      handler (n) {
        this.detail = {}
        if (n && JSON.stringify(n) !== 'n') {
          this.detail = n
        } else {
          this.detail = {}
        }
        // console.log(this.detail, 'this.detail')
      },
      immediate: true,
      deep: true
    }
  },
  // mounted() {
  //   var s = document.createElement("script");
  //   s.src = "https://static.xsolla.com/embed/paystation/1.0.7/widget.min.js";

  //   s.addEventListener(
  //     "load",
  //     (e) => {
  //       this.onSubmit().then((token) => {
  //         XPayStationWidget.init({
  //           access_token: token,
  //           sandbox: true, //remove this line when going live
  //         });
  //       });
  //       console.log(e, "init after");
  //     },
  //     false
  //   );
  //   var head = document.getElementsByTagName("head")[0];
  //   head.appendChild(s);
  // },
  methods: {
    myBrowser() {
      let userAgent = navigator.userAgent; //取得浏览器的userAgent字符串
      if (userAgent.indexOf("Firefox") > -1) {
        //判断是否Firefox浏览器
        return true;
      }

      let ua = userAgent.toLowerCase();
      return !!ua.match(/mqqbrowser|qzone|qqbrowser/i);
    },
    openUrl() {
      // this.$router.push({ name: "iterm" });
      window.open("./condition.html");
    },
    changeMoney(item, val) {
      return this.currency[item.currency] + val;
    },
    getDetail() {
      if (JSON.parse(localStorage.getItem("currentRoleLogin"))) {
        this.columns = JSON.parse(localStorage.getItem("currentRoleLogin"));
        // console.log(this.columns, 'this.columns===================')
        // this.columns.filter((item) => {
        //   return (item.text = item.name);
        // });
        // console.log(this.columns, "data from local user");
        this.detail.user_role_id = this.columns.id;
        this.detail.language = this.$store.state.locale;
        this.detail.goods_id = this.detail.id;
        this.detail.platform = this.columns.platform;
        this.detail.pkg = this.columns.pkg;
        this.onConfirm(this.columns);
      } else {
        const columns = JSON.parse(localStorage.getItem("currentNoRoleLogin"));
        this.detail.user_role_id = 0;
        this.detail.language = this.$store.state.locale;
        this.detail.goods_id = this.detail.id;
        this.detail.platform = columns.platform;
        this.detail.pkg = columns.pkg;
        this.onConfirm();
      }
    },
    //初始化支付
    pay(token) {
      // console.log("init before");
      var s = document.createElement("script");
      s.src = "https://static.xsolla.com/embed/paystation/1.0.7/widget.min.js";

      s.addEventListener(
        "load",
        () => {
          XPayStationWidget.init({
            access_token: token,
            sandbox: this.sandbox, //remove this line when going live
          });
          // console.log(e, "init after");
          // document.getElementById("payBtn").click();
          if (this.sandbox) {
            this.iframeURL =
              "https://sandbox-secure.xsolla.com/paystation3/desktop/list/?access_token=" +
              token;
          } else {
            this.iframeURL =
              "https://secure.xsolla.com/paystation3/desktop/list/?access_token=" +
              token;
          }

          // console.log(open, "open");
          // console.log(this.myBrowser(), "myBrowser");
          if (this.myBrowser()) {
            this.$emit("showCheck", "check", this.iframeURL); //iframe打开，体验不好，兼容部门PC和手机浏览器打不开的问题
            return;
          }
          let open = window.open("about:blank");
          if (open === null || typeof open === "undefined") {
            // window.location.href = this.iframeURL;
            this.$emit("showCheck", "check", this.iframeURL); //iframe打开，体验不好，兼容部门PC和手机浏览器打不开的问题
            return;
          }
          setTimeout(() => {
            open.location = this.iframeURL;
          }, 1000);
          // this.$emit("showCheck", "check", this.iframeURL); //iframe打开，体验不好，已启用
        },
        false
      );
      var head = document.getElementsByTagName("head")[0];
      head.appendChild(s);

      // console.log("init click", document.getElementById("payBtn"));
      // this.$emit("showLoading", false);
    },
    async onSubmit() {
      this.getDetail();
      if (!this.checked) {
        return Toast(this.$t("agree") + this.$t("iterm"));
      }
      this.loading = true;
      setTimeout(() => {
        this.loading = false;
        this.$emit("showPopup");
      }, 3000);
      // this.$emit("showLoading", true);
      let {
        language,
        platform,
        pkg,
        goods_id,
        buy_number,
        user_role_id,
        user_email,
      } = this.detail;
      // console.log(
      //   language,
      //   platform,
      //   pkg,
      //   goods_id,
      //   buy_number,
      //   user_role_id,
      //   user_email
      // );
      DotLog.clickLogs("check", { goodsId: goods_id }); //点击按钮打点传参
      let cPlatform = "";
      if (localStorage.getItem("platform") == 1) {
        cPlatform = "android";
      } else if (localStorage.getItem("platform") == 2) {
        cPlatform = "ios";
      }

      let res = await createOrder({
        language,
        platform,
        pkg,
        goods_id,
        buy_number,
        user_role_id,
        user_email,
        customize_platform: cPlatform,
      });
      if (res && res.code === 200) {
        this.pay(res.data.token);
        // return res.data.token;
      }
    },
    subtract() {
      if (this.params.buy_number > 1000) return;
      this.params.buy_number++;
    },
    add() {
      if (this.params.buy_number <= 1) return;
      this.params.buy_number--;
    },
    onConfirm(value) {
      value && (this.detail.user_role_id = value.id);
      value && (this.value = value.name);
      this.showPicker = false;
    },
  },
};
</script>
<style lang="scss" scoped>
.wrap {
  color: #fff;
  text-align: center;
  width: 100%;
  margin: 0 auto;
  border: 1px solid #ee5b24;
  box-sizing: border-box;
  overflow-x: hidden;
  background-color: rgba(0, 0, 0, 0.8);
}
.good-about {
  padding: 10px;
  display: flex;
  margin-top: 15px;
  font-size: 12px;
  border-radius: 10px;
  padding-right: 40px;

  & > div {
    display: flex;
    flex-flow: column;
    align-items: flex-start;
    line-height: 22px;
    color: #fff;
    justify-content: space-around;
    flex: 1;

    & > div:first-child {
      color: rgba(255, 255, 255, 70);
      flex-flow: row;
      text-align: left;
    }
  }
  .img-div {
    width: 80px;
    height: 70px;
    flex: none;
    .img-bg {
      width: 70px;
      height: 70px;
      margin-right: 15px;
      border-radius: 10px;
    }
  }
  img {
    width: 70px;
    height: 70px;
    margin-right: 15px;
    border-radius: 10px;
  }
  .title {
    font-size: 18px;
    color: #fff;
    font-size: 18px;
  }
  .orange {
    background-color: #ee5b24;
    border-top-left-radius: 10px;
    border-bottom-right-radius: 10px;
    font-weight: bold;
  }

  .delete {
    color: #c5c5c5;
    text-decoration: line-through;
    padding-right: 15px;
  }
}
.detail {
  margin: 0 10px 10px;
  background-color: rgba(255, 255, 255, 0.2);
  text-align: left;
  padding: 15px;
  color: rgba(255, 255, 255, 70);
  white-space: pre-wrap;
  div {
    line-height: 22px;
    max-height: 100px;
    overflow-x: hidden;
  }
}

.shoprightbot {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 190px;
  span {
    padding-left: 15px;
  }

  .van-checkbox__icon--checked .van-icon {
    background: red !important;
  }

  button {
    width: 24px;
    height: 26px;
    line-height: 1;
    font-size: 20px;
    background: #fff;
    color: #000;
    border: none;
  }
  input {
    width: 48px;
    line-height: 1;
  }

  .shopradd {
    display: flex;
  }
  .van-cell {
    padding: 0;
    line-height: 1;
  }
}
.form-div {
  width: 100%;
  margin-top: 20px;
  color: rgba(255, 255, 255, 70);
  .van-field__label {
    color: rgba(255, 255, 255, 0.7);
  }
  .van-field__value {
  }
}
.select-div {
  background: #1f1f1f;
  margin-bottom: 15px;
  .select {
    background-color: #fff;
    border: 1px solid #fff;
  }
  .van-field__value {
    line-height: 1;
    background-color: #fff;
  }
}
.total-num {
  height: 40px;
  color: #ee5b24;
  font-size: 14px;
  text-align: left;
  padding-left: 10px;
  span {
    font-size: 20px;
  }
}
.gray {
  color: #c5c5c5;
}
.line {
  text-decoration: underline;
  color: #838383;
}
.remember {
  padding-left: 10px;
  font-size: 12px;
  span {
    color: #838383;
    &:first-child {
      margin-left: -8px;
    }
  }
}
.pay-btn {
  background-color: #ee5b24;
  padding: 0 15px;
  border: 1px solid #ee5b24;
}
.to-pay {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
}
.self-cell {
  text-align: left;
  border-top: 1px solid #3e3e3e;
  border-bottom: 1px solid #3e3e3e;
  padding-top: 20px;
  height: 66px;
  margin-bottom: 20px;
  padding-left: 15px;
  padding-right: 15px;
  display: flex;
  div {
    line-height: 1.5;
    font-size: 14px;
    &:first-child {
      margin-right: 10px;
      color: rgba(255, 255, 255, 0.7);
    }
  }
}
</style>