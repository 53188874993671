<template>
  <div id="message-list" class="message-box">
    <p></p>
    <!-- 聊天记录框 -->
    <ul class="message-list">
      <li v-for="(item, index) in list" :key="index" :class="item.type">
        <span v-if="item.contentType == 'text'">{{ item.content }}</span>
        <img
          @click="previewPic(item.content)"
          :class="item.type + '-pic'"
          v-else-if="item.contentType == 'pic'"
          :src="item.content"
        />
      </li>
      <p class="tips" id="container">
        {{
          list.length > 0
            ? $t("contcttips")
            : $t("contactUS")
        }}
      </p>
    </ul>
    <div class="message-input">
      <!-- // 点击上传图片 -->
      <uploader :before-read="beforeRead" />
      <!-- // 输入文字，客户回复 -->
      <field
        v-model="userword"
        :placeholder="$t('enter')"
        class="message-input-box"
        maxlength="1000"
        @focus="inpFocus"
        @keyup.enter="sendText"
      />
      <!-- 点击按钮发送文字 -->
      <span class="send-icon" @click="sendText">
        <img src="../assets/img/icon/send.png" alt="" />
      </span>
    </div>
  </div>
</template>
<script>
import { sendTextApi, sendPicApi } from "@/api/message";
import { Field, Uploader, Toast, ImagePreview } from "vant";
export default {
  components: {
    Field,
    Uploader,
  },
  props: {
    messageList: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  // },
  data() {
    return {
      list: [],
      userword: "",
      showImg: false,
      images: [],
    };
  },
  watch: {
    messageList: {
      handler(newvalue) {
        if (this.list.length > 0 && newvalue.length > 0) {
          let flag = false;
          newvalue.forEach((im) => {
            if (!im.playerRead) {
              flag = true;
            }
          });
          if (flag) {
            var container = document.getElementById("container");
            container && container.scrollIntoView();
          }
        }
        this.list = newvalue;
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    enter(val) {
      console.log("Enter", val);
    },
    inpFocus() {
      // setTimeout(() => {
      //   console.log("height", document.body.scrollHeight);
      //   window.scrollTo(0, document.body.scrollHeight - 48);
      // }, 100);
    },
    /**
     * 点击预览图片
     */
    previewPic(url) {
      this.showImg = true;
      this.images[0] = url;
      ImagePreview({
        images: this.images,
        closeIconPosition: "top-right",
        closeable: true,
      });
    },
    /**
     * 提交到服务端
     */
    async beforeRead(file) {
      console.log(file);
      if (file.size / 1024 / 1024 >= 10) {
        const msg = this.$t("oversize");
        return Toast(msg);
      }
      if (
        file.type !== "image/jpeg" &&
        file.type !== "image/png" &&
        ((file.type !== "image/JPG") !== file.type) !== "image/Jjpg"
      ) {
        Toast(this.$t("picTypeerror"));
        return false;
      }
      let { roleId, userId, pscode } = this.mergeParams();
      let formData = new FormData();
      formData.append("roleId", roleId);
      formData.append("userId", userId);
      formData.append("pscode", pscode);
      formData.append("picFile", file);
      let res = await sendPicApi(formData);
      if (res.code === 200) {
        this.gethestoryMessage();
      }
    },
    /**
     * 获取到最近用户交流记录
     */
    async gethestoryMessage() {
      this.$emit("getMessageList");
    },
    /**
     * 发送文本消息
     */
    async sendText() {
      if (this.userword.trim().length <= 0) {
        return Toast(this.$t("inputMessagError"));
      }
      let { roleId, userId, pscode } = this.mergeParams();
      const params = {
        roleId,
        userId,
        pscode,
        text: this.userword,
      };
      let res = await sendTextApi(params);
      if (res.code === 200) {
        this.userword = "";
        this.gethestoryMessage();
      }
    },
    /**
     * 组合参数信息
     */
    mergeParams() {
      let { user_id } = JSON.parse(localStorage.getItem("user"));
      let { id } = localStorage.getItem("currentRole")
        ? JSON.parse(localStorage.getItem("currentRole"))
        : "";
      let projectInfo = JSON.parse(localStorage.getItem("projectInfo"));
      const params = {
        roleId: id || user_id,
        userId: user_id,
        pscode: projectInfo.project_code,
        text: this.userword,
      };
      return params;
    },
    // changeWords (val) {
    //   console.log('发送文本消息')
    // }
  },
};
</script>
<style lang="scss">
.message-box {
  & > p {
    text-align: left;
    background: #ee5b24;
    color: #fff;
    font-size: 28px;
    height: 50px;
    line-height: 50px;
    padding-left: 20px;
  }
  .message-list {
    background-color: rgb(239, 239, 239);
    height: 400px;
    overflow: scroll;
    // text-align: left;
    min-height: 200px;
    box-sizing: border-box;
    padding: 10px;
    padding-bottom: 50px;
    font-size: 12px;
    .answer {
      margin: 10px 0;
      text-align: left;
      width: auto;
      span {
        text-align: left;
        display: inline-block;
        border: 1px solid #cccccc;
        background-color: #ffffff;
        border-radius: 0 10px 10px 10px;
        max-width: 48%;
        padding: 5px;
        box-sizing: border-box;
        font-size: 12px;
        padding: 6px 10px;
      }
      .answer-pic {
        width: 48%;
      }
    }

    .question {
      text-align: right;
      margin: 10px 0;
      span {
        display: inline-block;
        border: 1px solid #cccccc;
        background-color: rgba(238, 91, 36, 0.1);
        border-radius: 10px 0 10px 10px;
        max-width: 48%;
        padding: 6px 10px;
        box-sizing: border-box;
        font-size: 12px;
        word-wrap: break-word;
      }
      .question-pic {
        width: 48%;
      }
    }
  }
  .message-input {
    width: 100%;
    background-color: #ffffff;
    display: flex;
    .send-icon {
      background-color: #ffffff;
      line-height: 40px;
      width: 40px;
      text-align: left;
      img {
        vertical-align: middle;
        width: 18px;
        margin-right: 10px;
      }
    }
  }
}
.van-uploader__upload {
  background-color: #ffffff;
  width: 40px;
  height: 40px;
  font-size: 14px;
}
.tips {
  margin: 10px auto;
  color: rgba(0, 0, 0, 0.3);
}
.van-action-sheet__close {
  line-height: 40px;
  color: #cccccc;
  // padding: 0 8px;
}
.van-field__control {
  font-size: 12px;
}
.van-uploader__upload-icon {
  margin-top: 5px;
  color: #666666;
}
.van-uploader__wrapper {
  margin-left: 10px;
  width: 30px;
}
.van-cell {
  padding: 8px 0;
}
</style>