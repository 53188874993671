// import Vue from 'vue'
import axios from 'axios'
// import router from "@/router/index";
import qs from 'qs'
import {
  Toast
} from 'vant'

const api = axios.create({
  // baseURL: process.env.VUE_APP_BASE_URL,
  baseURL: 'https://ul.haloapps.com/',
  timeout: 10000
})
api.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
api.interceptors.request.use(
  config => {
    if (config.jsonTransfor === "multipart/form-data") {
      config.headers['Content-Type'] = "multipart/form-data;charset=utf-8"
    } else {
      // 默认是form-data的参数类型
      if (config.method === 'post' && config.headers.post['Content-Type'].indexOf('application/x-www-form-urlencoded') !== -1) {
        config.data = qs.stringify(config.data, {
          allowDots: true
        })
      }
    }
    let token = localStorage.getItem("token");
    if (token) {
      try {
        config.headers["Authorization"] = "Bearer " + token;
        /** 设备唯一标识符   */
        // config.headers["uuid"] = "";
      } catch (e) {
        console.log(e)
      }
    }
    return config
  },
  error => {
    return Promise.reject(error)
  }
)
api.interceptors.response.use(
  response => {
    if (response.status === 200 && response.data.code == 200) {
      return response.data
    }
    if (response.status === 200 && response.data.code == 401) {
      //身份验证失败，重新登录
      localStorage.removeItem("token")
      localStorage.removeItem("user")
      location.reload()
      return;
    }
    Toast(response.data.message)
  },
  error => {
    console.log(error)
    return Promise.reject(error)
  }
)


export default api