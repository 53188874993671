import {
  facebookLogin
} from "@/api"
export default function payLoginClick() {
  window.FB.login(
    function (response) {
      if (response.status === "connected") {
        window.FB.api("/me", function (response) {
          console.log(response, "data from facebook");
          window.FB.api(
            "/" + response.id + "?fields=id,email,name",
            function (response) {
              // const userInfo = {
              //   id: response.id,
              //   email: response.email,
              //   name: response.name,
              // };
              // _this.pageLoading = true;
              console.log(response, "/");
              facebookLogin({
                  user_id: response.id
                })
                .then(function (res) {
                  console.log(res, "/ self interfac");

                  // FB登录成功
                  if (parseInt(res.code) === 200) {
                    console.log(res.data);
                    localStorage.setItem("user", JSON.stringify(res.data));
                    localStorage.setItem("token", res.data.token);
                    if (res.data.roles && res.data.roles.length > 1) {
                      localStorage.setItem("roles", JSON.stringify(res.data.roles));
                      localStorage.removeItem("currentRole");
                      this.$emit("showRolePopup");
                    } else if (res.data.roles && res.data.roles.length === 1) {
                      localStorage.removeItem("roles");
                      localStorage.setItem("currentRole", JSON.stringify(res.data.roles[0]));
                    }
                    // sessionStorage.setItem(
                    //   "user_info",
                    //   JSON.stringify(userInfo)
                    // );
                    window.location.reload();
                  }
                })
                .catch(function (error) {
                  console.log("FB err: ", error);
                })
                .finally(() => {
                  // _this.pageLoading = false;
                });
            }
          );
        });
      } else if (response.status === "not_authorized") {
        // The person is logged into Facebook, but not your app.
      } else {
        // The person is not logged into Facebook, so we're not sure if
        // they are logged into this app or not.
      }
    }, {
      scope: "public_profile,email",
    }
  );
}